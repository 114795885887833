.form {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  max-width: 400px;
  margin: 30px auto 0;
  padding: 40px;

  h1 {
    color: #e4e7ea;
  }
}

.input {
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.inputIcon {
  background-color: #343b41;
  border: 1px solid #23282c;

  svg {
    color: #e4e7ea;
  }
}

.agree {
  label {
    position: relative;
    top: 3px;
  }
}
