.DateRangePickerInput__withBorder {
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-top: 2px;
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateInput {
  background: none;
  color: #e4e7ea;
}

.DateInput_input {
  background: none;
  color: #e4e7ea;
}

.DateRangePickerInput_arrow {
  height: 2px;
  background: #e4e7ea;
  width: 10px;
  margin-right: 4px;
}
