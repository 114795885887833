@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.main {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a;
}

.settings {
  display: flex;
  align-items: center;
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
  padding: 10px 20px;
}

.dateInstructions {
  display: none;

  @include breakpoint($non-mobile) {
    display: block;
    margin-right: 10px;
    color: #e4e7ea;
  }
}

.pointsInstructions {
  display: none;

  @include breakpoint($non-mobile) {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    color: #e4e7ea;
  }
}

.points {
  display: inline-block;
}

.toggle {
  padding: 6px 8px 2px;
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown {
  background-color: #3a4149;
  border: 2px solid #23282c;
}

.rooms {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  padding: 20px;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1);
}
