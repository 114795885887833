.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.cardTitle {
  color: #e4e7ea;
  padding: 20px 20px 0;

  h2 {
    margin: 0;
  }
}

.cardBody {
  padding: 20px;
}
