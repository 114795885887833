@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.mainOverview {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 20px;
}

.mainCards {
  column-count: 1;
  column-gap: 20px;
  margin: 20px;
  color: #fff;

  @include breakpoint($medium-screen) {
    column-count: 2;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #3a4149;
  border: 1px solid #23282c;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
}


.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #3a4149;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  cursor: pointer;
}
.body {
  width: 100%;
  flex: 1 1 auto;
  padding: 20px 20px 30px;
  color: #e4e7ea;
}
.title {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

