@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.navOpen {
  position: relative;
  display: inline-block;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.icon {
  margin-right: 15px;
  cursor: pointer;
  color: #c8ced3;

  &:hover {
    color: #fff;
  }
}

.error {
  position: absolute;
  top: -2px;
  left: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f86c6b;
}
