@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.mote {
  padding: 15px;
  color: #e4e7ea;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
}

.new {
  background-color: #23282c;
}

.old {
  background-color: #3a4149;
}

.led {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  border: solid 2px #e4e7ea;
  background: none;
}

.online {
  background: #4dbd74;
}

.offline {
  background: #f86c6b;
}

.inactive {
  background: #ffc107;
}

.title {
  color: #e4e7ea;
}

.titleHeading {
  display: inline-block;
}

.room {
  margin-bottom: 5px;
}

.message {
  margin-bottom: 15px;
}

.label {
  color: #73818f;
}

.units {
  font-size: 12px;
}

.time {
  margin-left: 10px;
}
