.toast {
  border: 1px solid #23282c;
}
.header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #343b41;
  border: none;
  border-bottom: 1px solid #23282c;
  color: #e4e7ea;

  &.red {
    background: #f86c6b;
    color: #e4e7ea;
  }

  .icon {
    margin-right: 10px;
    font-size: 18px;
  }

  .title {
    margin-right: 30px;
  }
}
.body {
  border: none;
  background-color: #3a4149;
  color: #e4e7ea;

  a,
  a:visited {
    color: #e4e7ea;
  }
}
