@import url('https://fonts.googleapis.com/css?family=Martel+Sans');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #2f353a;
  font-family: 'Martel Sans', sans-serif;
}
