@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50px;
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.headerSearch {
  display: block;
}

.headerAvatar {
  display: block;
}

.brand {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  height: 100%;
}

.logo {
  position: relative;
  top: 1px;
  width: 75%;
  height: auto;
  margin-top: 1px;

  @include breakpoint($non-mobile) {
    width: 100%;
  }
}
