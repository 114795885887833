@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.main {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a;
}

.gridContainer {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 20px;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1);
}
