@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.main {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint($non-mobile) {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  }
}
.checkbox {
  margin-bottom: 10px;
  label {
    position: relative;
    top: 4px;
    color: #e4e7ea;
  }
}
.noResults {
  margin: 30px 0 10px;
  color: #e4e7ea;
  display: flex;
  flex-direction: column;
}
.noResultsIcon {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #73818f;
}
.noResultsMessage {
  width: 100%;
  text-align: center;
}
.history {
  width: 100%;
  margin-bottom: 20px;

  @include breakpoint($non-mobile) {
    width: 30%;
    padding-left: 20px;
    margin-bottom: 0;
  }
}
.floorplan {
  width: 100%;

  @include breakpoint($non-mobile) {
    width: 70%;
  }
}
.svg {
  path,
  polygon,
  line,
  polyline,
  rect {
    fill: none;
    stroke: #c8ced3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
.label {
  path {
    stroke: none;
    fill: #c8ced3;
  }
}

.id {
  path {
    stroke: none;
    fill: #fff;
  }
}
