@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.room {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  color: #e4e7ea;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.titleWrapper {
  > div {
    display: inline-block;
  }
}

.title {
  font-size: 16px;
  text-transform: uppercase;

  @include breakpoint($non-mobile) {
    font-size: 24px;
  }
}

.subtitle {
  margin-left: 15px;
  color: #73818f;
}

.icon {
  margin-right: 5px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}
