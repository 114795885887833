body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.badge-danger {
  color: #fff;
  background-color: #f86c6b;
}

.dropdown-toggle::after {
  color: white;
}

.dropdown-menu {
  min-width: 5rem;
  background: #3a4149;
  border-color: #3a4149;
}

.dropdown-header {
  color: #e4e7ea;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.dropdown-divider {
  border-top: 1px solid #23282c;
}

.dropdown-item {
  position: relative;
  color: #e4e7ea;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item.radio {
  padding-left: 30px;
}

.dropdown-item.radio:before {
  content: '';
  position: absolute;
  top: 9px;
  left: 10px;
  width: 10px;
  height: 10px;
  border: solid thin white;
  border-radius: 50%;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  background: #23282c;
}

.dropdown-item.active,
.dropdown-item:active {
  background: none;
}

.dropdown-item.radio.active:after,
.dropdown-item.radio:active:after {
  content: '';
  position: absolute;
  top: 11px;
  left: 12px;
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
}

.nav-link {
  padding: 0;
  border-bottom: solid thin #343b41;
}

.nav-link:first-child {
  border-top: solid thin #343b41;
}

.nav-link.disabled {
  padding: 1rem 1rem 0.7rem;
}

.nav-link a {
  display: block;
  width: 100%;
  padding: 1rem 1rem 0.7rem;
  color: #fff;
}

.nav-link.active a,
.nav-link a.is-active {
  color: #fff;
  background: #343b41;
}

.nav-link a:hover {
  background: #20a8d8;
  text-decoration: none;
}
