.event {
  display: flex;
  padding: 10px;
  color: #c8cfd6;

//   &:nth-child(1) {
//     background: #2079d8;
//   }

//   &:nth-child(2) {
//     background: #3686db;
//   }

//   &:nth-child(3) {
//     background: #4c93df;
//   }

//   &:nth-child(4) {
//     background: #62a1e3;
//   }

//   &:nth-child(5) {
//     background: #79aee7;
//   }
}

.hot {
  background: #2079d8;
}

.medium {
  background: #3686db;
}

.mild {
  background: #4c93df;
}

.cold {
  background: none;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;

  .svg {
    width: 60% !important;
    height: auto;
  }

  svg.fa-lightbulb {
    width: 40% !important;
  }

  svg.fa-lightbulb-on {
    width: 75% !important;
  }
}
.info {
  width: 80%;
  padding-left: 15px;
}
.time {
  font-size: 18px;
}
