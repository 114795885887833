@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';
.locations {
  position: relative;
  display: inline-block;
}

.titleWrapper {
  cursor: pointer;

  &.titleOpen {
    .location,
    .icon {
      color: #fff;
    }
    .icon {
      transform: rotate(45deg);
    }
  }

  &.titleClosed {
    .location,
    .icon {
      color: #c8ced3;
    }
  }
  &:hover {
    .location,
    .icon {
      color: #fff;
    }
  }
}

.location {
  display: inline-block;
  margin-right: 10px;
  text-transform: uppercase;
  color: #c8ced3;
  transition: all 1s ease-out;
  font-size: 14px;

  @include breakpoint($non-mobile) {
    font-size: 16px;
  }
}

.icon {
  color: #c8ced3;
  transition: all 1s ease-out;
}

.dropdown {
  background-color: #3a4149;
  border: 2px solid #23282c;
}
