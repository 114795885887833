@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.main {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a;
}

.filters {
  float: left;
  width: 100%;
  padding: 20px;
  color: #e4e7ea;
}

.checkbox {
  label {
    position: relative;
    top: 4px;
  }
}

.log {
  float: left;
  width: 100%;
  padding: 0 20px 20px;
}

.row {
  float: left;
  width: 100%;
  color: #e4e7ea;
  display: grid;
  border-bottom: 1px solid #23282c;
  padding: 10px 0;

  @include breakpoint($non-mobile) {
    grid-template-columns: 95px repeat(7, 1fr);
    padding: 5px 0;
  }
}

.heading {
  display: none;

  @include breakpoint($non-mobile) {
    display: grid;
    margin-bottom: 10px;
    border-bottom: 2px solid #23282c;
  }
}

.item {
  margin-right: 20px;
  align-self: center;

  span {
    color: #7b8084;
    @include breakpoint($non-mobile) {
      display: none;
    }
  }
}
