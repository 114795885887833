@import url(https://fonts.googleapis.com/css?family=Martel+Sans);
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.badge-danger {
  color: #fff;
  background-color: #f86c6b;
}

.dropdown-toggle::after {
  color: white;
}

.dropdown-menu {
  min-width: 5rem;
  background: #3a4149;
  border-color: #3a4149;
}

.dropdown-header {
  color: #e4e7ea;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.dropdown-divider {
  border-top: 1px solid #23282c;
}

.dropdown-item {
  position: relative;
  color: #e4e7ea;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item.radio {
  padding-left: 30px;
}

.dropdown-item.radio:before {
  content: '';
  position: absolute;
  top: 9px;
  left: 10px;
  width: 10px;
  height: 10px;
  border: solid thin white;
  border-radius: 50%;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: white;
  background: #23282c;
}

.dropdown-item.active,
.dropdown-item:active {
  background: none;
}

.dropdown-item.radio.active:after,
.dropdown-item.radio:active:after {
  content: '';
  position: absolute;
  top: 11px;
  left: 12px;
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;
}

.nav-link {
  padding: 0;
  border-bottom: solid thin #343b41;
}

.nav-link:first-child {
  border-top: solid thin #343b41;
}

.nav-link.disabled {
  padding: 1rem 1rem 0.7rem;
}

.nav-link a {
  display: block;
  width: 100%;
  padding: 1rem 1rem 0.7rem;
  color: #fff;
}

.nav-link.active a,
.nav-link a.is-active {
  color: #fff;
  background: #343b41;
}

.nav-link a:hover {
  background: #20a8d8;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  background: #2f353a;
  font-family: 'Martel Sans', sans-serif; }

.Dashboard_main__1qmyE {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a; }

.Dashboard_loading__3nSsb {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.Dashboard_icon__1et71 {
  font-size: 20vw;
  color: rgba(34, 31, 31, 0.1); }

.Dashboard_settings__1mQHk {
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
  padding: 10px; }

.Dashboard_filter__1wZgN {
  display: inline; }

.Dashboard_label__2aekn {
  margin-right: 5px;
  color: #c8ced3;
  text-transform: uppercase; }

.Dashboard_list__2wPoi {
  display: inline-block;
  font-size: 16px;
  color: #e4e7ea;
  padding: 0.6em 1.4em 0.5em 0.8em;
  margin: 0 10px 0 0;
  box-sizing: border-box;
  border: 1px solid #23282c;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #515b65;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2323282c%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), -webkit-linear-gradient(top, #515b65 0%, #515b65 100%);
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2323282c%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #515b65 0%, #515b65 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%; }

.Dashboard_list__2wPoi::-ms-expand {
  display: none; }

.Dashboard_room__3Au-S {
  margin-right: 10px; }

.Dashboard_mote__1u6q9 {
  margin-right: 0; }

.Dashboard_mainOverview__iggvw {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 20px; }

.Dashboard_mainCards__2KF-l {
  -webkit-column-count: 1;
          column-count: 1;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin: 20px;
  color: #fff; }
  @media (min-width: 65.625em) {
    .Dashboard_mainCards__2KF-l {
      -webkit-column-count: 2;
              column-count: 2; } }

.Dashboard_card__319Zy {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  background-color: #3a4149;
  border: 1px solid #23282c;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  /* Force varying heights to simulate dynamic content */ }
  .Dashboard_card__319Zy:first-child {
    height: 485px; }
  .Dashboard_card__319Zy:nth-child(2) {
    height: 200px; }
  .Dashboard_card__319Zy:nth-child(3) {
    height: 265px; }

.Room_card__3rmrt {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #3a4149;
  border: 1px solid #23282c;
  border-radius: 0.25rem; }

.Room_body__3z6QH {
  width: 100%;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 20px 20px 30px;
  color: #e4e7ea; }

.Room_title__rqiyk {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase; }

.Room_date__2S57n {
  font-size: 14px;
  line-height: 14px;
  color: #73818f; }

.Room_report__2mril {
  width: 100%;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .Room_report__2mril:last-child {
    margin-bottom: 0; }
  .Room_report__2mril .Room_value__2saDh .Room_number__17n8Q {
    display: block;
    position: relative;
    top: 5px;
    min-width: 65px;
    margin-right: 5px;
    font-size: 32px;
    line-height: 32px; }
  .Room_report__2mril .Room_graph__vIevE {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .Room_report__2mril .Room_graph__vIevE .Room_units__34mz1 {
      font-size: 13px;
      color: #73818f; }
    .Room_report__2mril .Room_graph__vIevE .Room_bar__3zJsJ {
      height: 5px;
      background-color: #515b65; }

.Room_temp__1CiTx div {
  background-color: #20a8d8; }

.Room_light__29naA div {
  background-color: #4dbd74; }

.Room_humidity__26pZs div {
  background-color: #ffc107; }

.Motes_main__1pXSd {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a; }

.Motes_gridContainer__45OCv {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 20px; }

.Motes_loading__2Nd7E {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.Motes_icon__1QH0o {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1); }

.Mote_mote__29MiD {
  padding: 15px;
  color: #e4e7ea;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem; }

.Mote_new__24Vra {
  background-color: #23282c; }

.Mote_old__290O8 {
  background-color: #3a4149; }

.Mote_led__j5E3c {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  border: solid 2px #e4e7ea;
  background: none; }

.Mote_online__1Or4d {
  background: #4dbd74; }

.Mote_offline__26Vzz {
  background: #f86c6b; }

.Mote_inactive__2tZmp {
  background: #ffc107; }

.Mote_title__k2xGg {
  color: #e4e7ea; }

.Mote_titleHeading__17u-Y {
  display: inline-block; }

.Mote_room__3zotY {
  margin-bottom: 5px; }

.Mote_message__1TFvo {
  margin-bottom: 15px; }

.Mote_label__SA0rV {
  color: #73818f; }

.Mote_units__ttirK {
  font-size: 12px; }

.Mote_time__e_lRb {
  margin-left: 10px; }

.Sensors_button__2URxW {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid #23282c;
  background-color: #343b41;
  border-radius: 0.25rem;
  color: #e4e7ea;
  text-align: left;
  margin-bottom: 15px; }
  .Sensors_button__2URxW:last-child {
    margin-bottom: 0; }

.Sensors_open__3-XN- {
  display: block; }
  .Sensors_open__3-XN- svg {
    color: inherit; }

.Sensors_closed__1jF3a {
  display: block; }
  .Sensors_closed__1jF3a svg {
    color: #fff; }

.Sensors_label__ICT_- {
  color: #73818f; }

.Sensors_value__1xckM {
  margin-left: 5px; }

.Sensors_icon__C8Zyw {
  position: absolute;
  top: 10px;
  right: 15px; }
  .Sensors_icon__C8Zyw svg {
    font-size: 22px; }

.Sensors_chart__1FsWs {
  margin-top: 15px; }

.Sensors_blue__P5ykd {
  color: #20a8d8; }

.Sensors_green__1fsBi {
  color: #4dbd74; }

.Sensors_yellow__3TjaV {
  color: #ffc107; }

.Sensors_red__1WLOE {
  color: #f86c6b; }

.Environment_main__2X4xp {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a; }

.Environment_settings__18hup {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
  padding: 10px 20px; }

.Environment_dateInstructions__38Not {
  display: none; }
  @media (min-width: 46.875em) {
    .Environment_dateInstructions__38Not {
      display: block;
      margin-right: 10px;
      color: #e4e7ea; } }

.Environment_pointsInstructions__2K5i0 {
  display: none; }
  @media (min-width: 46.875em) {
    .Environment_pointsInstructions__2K5i0 {
      display: block;
      margin-left: 10px;
      margin-right: 10px;
      color: #e4e7ea; } }

.Environment_points__3mUIe {
  display: inline-block; }

.Environment_toggle__2_EpX {
  padding: 6px 8px 2px;
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.Environment_dropdown__1fWLI {
  background-color: #3a4149;
  border: 2px solid #23282c; }

.Environment_rooms__1QTuQ {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  padding: 20px; }

.Environment_loading__peCcw {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.Environment_icon__30eDk {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1); }

.DateRangePickerInput__withBorder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 5px;
  padding-top: 2px;
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateInput {
  background: none;
  color: #e4e7ea;
}

.DateInput_input {
  background: none;
  color: #e4e7ea;
}

.DateRangePickerInput_arrow {
  height: 2px;
  background: #e4e7ea;
  width: 10px;
  margin-right: 4px;
}

.Loader_loading__QnsP3 {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.Loader_icon__1vnvp {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1); }

.Room_room__2xpAS {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  color: #e4e7ea; }

.Room_header__19ri0 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 20px; }

.Room_titleWrapper__1sJD8 > div {
  display: inline-block; }

.Room_title__27Ip4 {
  font-size: 16px;
  text-transform: uppercase; }
  @media (min-width: 46.875em) {
    .Room_title__27Ip4 {
      font-size: 24px; } }

.Room_subtitle__22qLq {
  margin-left: 15px;
  color: #73818f; }

.Room_icon__1Ce9z {
  margin-right: 5px;
  cursor: pointer; }
  .Room_icon__1Ce9z:last-child {
    margin-right: 0; }

.Chart_loader__2COdc {
  padding: 10px 20px 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 50px; }

.Activity_main__1YFqx {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a; }

.Activity_settings__3Q99t {
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
  padding: 10px; }

.Activity_charts__3zJrv {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  padding: 20px; }

.Activity_loading__3YH8t {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.Activity_icon__2mwJ7 {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1); }

.FilterMotes_filter__1V23Q {
  display: inline; }

.FilterMotes_label__1QMLn {
  margin-right: 5px;
  color: #c8ced3;
  text-transform: uppercase; }

.FilterMotes_list__1GM_S {
  display: inline-block;
  font-size: 16px;
  color: #e4e7ea;
  padding: 0.6em 1.4em 0.5em 0.8em;
  margin: 0 10px 0 0;
  box-sizing: border-box;
  border: 1px solid #23282c;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #515b65;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2323282c%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), -webkit-linear-gradient(top, #515b65 0%, #515b65 100%);
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2323282c%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #515b65 0%, #515b65 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%; }

.FilterMotes_list__1GM_S::-ms-expand {
  display: none; }

.FilterMotes_room__3Flua {
  margin-right: 10px; }

.FilterMotes_mote__2ZSwi {
  margin-right: 0; }

.Alert_card__13TUp {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  margin-bottom: 20px; }

.Alert_cardTitle__3Wit4 {
  color: #e4e7ea;
  padding: 20px 20px 0; }
  .Alert_cardTitle__3Wit4 h2 {
    margin: 0; }

.Alert_cardBody__2osOr {
  padding: 20px; }

.Floorplan_main__1IvAD {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (min-width: 46.875em) {
    .Floorplan_main__1IvAD {
      -webkit-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; } }

.Floorplan_checkbox__12wbv {
  margin-bottom: 10px; }
  .Floorplan_checkbox__12wbv label {
    position: relative;
    top: 4px;
    color: #e4e7ea; }

.Floorplan_noResults__HpVqp {
  margin: 30px 0 10px;
  color: #e4e7ea;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Floorplan_noResultsIcon__1Yrm6 {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #73818f; }

.Floorplan_noResultsMessage__kp80H {
  width: 100%;
  text-align: center; }

.Floorplan_history__2MRiL {
  width: 100%;
  margin-bottom: 20px; }
  @media (min-width: 46.875em) {
    .Floorplan_history__2MRiL {
      width: 30%;
      padding-left: 20px;
      margin-bottom: 0; } }

.Floorplan_floorplan__21UBq {
  width: 100%; }
  @media (min-width: 46.875em) {
    .Floorplan_floorplan__21UBq {
      width: 70%; } }

.Floorplan_svg__29Y20 path,
.Floorplan_svg__29Y20 polygon,
.Floorplan_svg__29Y20 line,
.Floorplan_svg__29Y20 polyline,
.Floorplan_svg__29Y20 rect {
  fill: none;
  stroke: #c8ced3;
  stroke-linecap: round;
  stroke-linejoin: round; }

.Floorplan_label__3mVqs path {
  stroke: none;
  fill: #c8ced3; }

.Floorplan_id__2WB64 path {
  stroke: none;
  fill: #fff; }

.Event_event__wJJ3i {
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  color: #c8cfd6; }

.Event_hot__1AoiY {
  background: #2079d8; }

.Event_medium__d4Fdb {
  background: #3686db; }

.Event_mild__3OQSb {
  background: #4c93df; }

.Event_cold__3NyeT {
  background: none; }

.Event_icon__2Qixx {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 20%; }
  .Event_icon__2Qixx .Event_svg__2hDjK {
    width: 60% !important;
    height: auto; }
  .Event_icon__2Qixx svg.Event_fa-lightbulb__1zZMc {
    width: 40% !important; }
  .Event_icon__2Qixx svg.Event_fa-lightbulb-on__3fA0D {
    width: 75% !important; }

.Event_info__1tF46 {
  width: 80%;
  padding-left: 15px; }

.Event_time__3fbvN {
  font-size: 18px; }

.RoseboxFloorplan_main__1KksT {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (min-width: 46.875em) {
    .RoseboxFloorplan_main__1KksT {
      -webkit-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; } }

.RoseboxFloorplan_checkbox__16UYz {
  margin-bottom: 10px; }
  .RoseboxFloorplan_checkbox__16UYz label {
    position: relative;
    top: 4px;
    color: #e4e7ea; }

.RoseboxFloorplan_noResults__L6h-u {
  margin: 30px 0 10px;
  color: #e4e7ea;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.RoseboxFloorplan_noResultsIcon__28TXi {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #73818f; }

.RoseboxFloorplan_noResultsMessage__1Y6IV {
  width: 100%;
  text-align: center; }

.RoseboxFloorplan_history__3F-Tj {
  width: 100%;
  margin-bottom: 20px; }
  @media (min-width: 46.875em) {
    .RoseboxFloorplan_history__3F-Tj {
      width: 30%;
      padding-left: 20px;
      margin-bottom: 0; } }

.RoseboxFloorplan_floorplan__2RVDO {
  width: 100%; }
  @media (min-width: 46.875em) {
    .RoseboxFloorplan_floorplan__2RVDO {
      width: 70%; } }

.RoseboxFloorplan_svg__18NvJ path,
.RoseboxFloorplan_svg__18NvJ polygon,
.RoseboxFloorplan_svg__18NvJ line,
.RoseboxFloorplan_svg__18NvJ polyline,
.RoseboxFloorplan_svg__18NvJ rect {
  fill: none;
  stroke: #c8ced3;
  stroke-linecap: round;
  stroke-linejoin: round; }

.RoseboxFloorplan_label__2E8AR path {
  stroke: none;
  fill: #c8ced3; }

.RoseboxFloorplan_id__uX5Lo path {
  stroke: none;
  fill: #fff; }

.RoseboxFloorplan_cls1__2asz9 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 1px; }

.RoseboxFloorplan_doorObject__INpVv {
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in; }

.RoseboxFloorplan_door1__1enFK {
  fill: #fff; }

.RoseboxFloorplan_door2__2-R0Q {
  fill: #fff; }

.RoseboxFloorplan_doorGreenOpen__2C0xW {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-origin: 140.33px 59.19px;
          transform-origin: 140.33px 59.19px; }

.RoseboxFloorplan_doorGreenClosed__1KsTZ {
  -webkit-transform: rotate(24.6deg);
          transform: rotate(24.6deg);
  -webkit-transform-origin: 140.33px 59.19px;
          transform-origin: 140.33px 59.19px; }

.RoseboxFloorplan_doorRedOpen__1jbcr {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-origin: 515.06px 59.19px;
          transform-origin: 515.06px 59.19px; }

.RoseboxFloorplan_doorRedClosed__4ECCs {
  -webkit-transform: rotate(-24.6deg);
          transform: rotate(-24.6deg);
  -webkit-transform-origin: 515.06px 59.19px;
          transform-origin: 515.06px 59.19px; }

.RoseboxFloorplan_motionGreenStop__3Cp6b {
  -webkit-transition: fill 0.5s ease-in;
  transition: fill 0.5s ease-in;
  fill: none; }

.RoseboxFloorplan_motionGreenStart__ucEvD {
  -webkit-transition: fill 0.5s ease-in;
  transition: fill 0.5s ease-in;
  fill: #4dbd74; }

.RoseboxFloorplan_motionRedStop__3rtAV {
  -webkit-transition: fill 0.3s ease-in;
  transition: fill 0.3s ease-in;
  fill: none; }

.RoseboxFloorplan_motionRedStart__2aioJ {
  -webkit-transition: fill 0.3s ease-in;
  transition: fill 0.3s ease-in;
  fill: #f86c6b; }

.RoseboxFloorplan_lightsGreenOn__2_R-b {
  -webkit-transition: fill 0.3s ease-in;
  transition: fill 0.3s ease-in;
  fill: #4dbd74; }

.RoseboxFloorplan_lightsRedOn__Ts58U {
  -webkit-transition: fill 0.3s ease-in;
  transition: fill 0.3s ease-in;
  fill: #f86c6b; }

.RoseboxFloorplan_lightsGreenOff__3CiNF {
  -webkit-transition: fill 0.3s ease-in;
  transition: fill 0.3s ease-in;
  fill: rgba(77, 189, 116, 0.2); }

.RoseboxFloorplan_lightsRedOff__1_ecT {
  -webkit-transition: fill 0.3s ease-in;
  transition: fill 0.3s ease-in;
  fill: rgba(248, 108, 107, 0.2); }

.Log_main__34gqk {
  position: relative;
  margin-top: 50px;
  grid-area: main;
  background-color: #2f353a; }

.Log_filters__1IM2C {
  float: left;
  width: 100%;
  padding: 20px;
  color: #e4e7ea; }

.Log_checkbox__x-MS4 label {
  position: relative;
  top: 4px; }

.Log_log__3uarj {
  float: left;
  width: 100%;
  padding: 0 20px 20px; }

.Log_row__AR3A0 {
  float: left;
  width: 100%;
  color: #e4e7ea;
  display: grid;
  border-bottom: 1px solid #23282c;
  padding: 10px 0; }
  @media (min-width: 46.875em) {
    .Log_row__AR3A0 {
      grid-template-columns: 95px repeat(7, 1fr);
      padding: 5px 0; } }

.Log_heading__35WTy {
  display: none; }
  @media (min-width: 46.875em) {
    .Log_heading__35WTy {
      display: grid;
      margin-bottom: 10px;
      border-bottom: 2px solid #23282c; } }

.Log_item__12ZBE {
  margin-right: 20px;
  -webkit-align-self: center;
          align-self: center; }
  .Log_item__12ZBE span {
    color: #7b8084; }
    @media (min-width: 46.875em) {
      .Log_item__12ZBE span {
        display: none; } }


.LoginForm_form__1wxAU {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #3a4149;
  background-clip: border-box;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  max-width: 400px;
  margin: 30px auto 0;
  padding: 40px; }
  .LoginForm_form__1wxAU h1 {
    color: #e4e7ea; }

.LoginForm_input__1hhzv {
  color: #e4e7ea;
  background-color: #515b65;
  background-clip: padding-box;
  border: 1px solid #23282c;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.LoginForm_inputIcon__2zaMS {
  background-color: #343b41;
  border: 1px solid #23282c; }
  .LoginForm_inputIcon__2zaMS svg {
    color: #e4e7ea; }

.LoginForm_agree__2dwAL label {
  position: relative;
  top: 3px; }

.Admin_mainOverview__3960I {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  padding: 20px; }

.Admin_mainCards__268CI {
  -webkit-column-count: 1;
          column-count: 1;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin: 20px;
  color: #fff; }
  @media (min-width: 65.625em) {
    .Admin_mainCards__268CI {
      -webkit-column-count: 2;
              column-count: 2; } }

.Admin_card__2FWId {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  background-color: #3a4149;
  border: 1px solid #23282c;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box; }

.Admin_card__2FWId {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #3a4149;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
  cursor: pointer; }

.Admin_body__1B4r9 {
  width: 100%;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 20px 20px 30px;
  color: #e4e7ea; }

.Admin_title__2TfXq {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase; }

.PrivateRoute_gridContainer__1axY8 {
  display: grid;
  grid-template-columns: 1fr;
  /* Side nav is hidden on mobile */
  grid-template-rows: 1fr 50px;
  grid-template-areas: 'main' 'footer';
  width: 100%;
  height: 100vh; }

@media (min-width: 46.875em) {
  .PrivateRoute_navOpen__2SJtU {
    grid-template-columns: 240px 1fr;
    /* Show the side nav for non-mobile screens */
    grid-template-areas: 'sidenav main' 'sidenav footer'; } }

.Toaster_toaster__2goaN {
  position: absolute;
  top: 60px;
  right: 15px;
  z-index: 499;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.25rem;
  color: #e4e7ea; }

.Popup_toast__3lS2_ {
  border: 1px solid #23282c; }

.Popup_header__1U09N {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #343b41;
  border: none;
  border-bottom: 1px solid #23282c;
  color: #e4e7ea; }
  .Popup_header__1U09N.Popup_red__cLvcN {
    background: #f86c6b;
    color: #e4e7ea; }
  .Popup_header__1U09N .Popup_icon__2M92D {
    margin-right: 10px;
    font-size: 18px; }
  .Popup_header__1U09N .Popup_title__2HSAr {
    margin-right: 30px; }

.Popup_body__1aI0D {
  border: none;
  background-color: #3a4149;
  color: #e4e7ea; }
  .Popup_body__1aI0D a,
  .Popup_body__1aI0D a:visited {
    color: #e4e7ea; }

.Header_header__2j8UX {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 50px;
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 20px; }

.Header_headerSearch__13yCv {
  display: block; }

.Header_headerAvatar__26ZJp {
  display: block; }

.Header_brand__2CNTd {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  width: 100px;
  height: 100%; }

.Header_logo__262nz {
  position: relative;
  top: 1px;
  width: 75%;
  height: auto;
  margin-top: 1px; }
  @media (min-width: 46.875em) {
    .Header_logo__262nz {
      width: 100%; } }

.NavOpen_navOpen__3KeHE {
  position: relative;
  display: inline-block; }

.NavOpen_show__3z85k {
  display: inline-block; }

.NavOpen_hide__3do4w {
  display: none; }

.NavOpen_icon__2LbBS {
  margin-right: 15px;
  cursor: pointer;
  color: #c8ced3; }
  .NavOpen_icon__2LbBS:hover {
    color: #fff; }

.NavOpen_error__fgbsX {
  position: absolute;
  top: -2px;
  left: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f86c6b; }

.UserMenu_titleWrapper__1T9tP {
  cursor: pointer; }
  .UserMenu_titleWrapper__1T9tP.UserMenu_titleOpen__1Utfv .UserMenu_location__2om8F,
  .UserMenu_titleWrapper__1T9tP.UserMenu_titleOpen__1Utfv .UserMenu_icon__3c5bv {
    color: #fff; }
  .UserMenu_titleWrapper__1T9tP.UserMenu_titleOpen__1Utfv .UserMenu_icon__3c5bv {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .UserMenu_titleWrapper__1T9tP.UserMenu_titleClosed__38hCB .UserMenu_location__2om8F,
  .UserMenu_titleWrapper__1T9tP.UserMenu_titleClosed__38hCB .UserMenu_icon__3c5bv {
    color: #c8ced3; }
  .UserMenu_titleWrapper__1T9tP:hover .UserMenu_location__2om8F,
  .UserMenu_titleWrapper__1T9tP:hover .UserMenu_icon__3c5bv {
    color: #fff; }

.UserMenu_user__2HcCX {
  display: none;
  position: relative;
  margin: 0 10px;
  text-transform: uppercase;
  color: #c8ced3;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out; }
  @media (min-width: 46.875em) {
    .UserMenu_user__2HcCX {
      display: inline-block; } }

.UserMenu_icon__3c5bv {
  color: #c8ced3;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out; }

.UserMenu_dropdown__1eNAl {
  background-color: #3a4149;
  border: 2px solid #23282c; }

.SitesMenu_locations__8llMX {
  position: relative;
  display: inline-block; }

.SitesMenu_titleWrapper__27-hb {
  cursor: pointer; }
  .SitesMenu_titleWrapper__27-hb.SitesMenu_titleOpen__3fv9z .SitesMenu_location__DGTgU,
  .SitesMenu_titleWrapper__27-hb.SitesMenu_titleOpen__3fv9z .SitesMenu_icon__ertKO {
    color: #fff; }
  .SitesMenu_titleWrapper__27-hb.SitesMenu_titleOpen__3fv9z .SitesMenu_icon__ertKO {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .SitesMenu_titleWrapper__27-hb.SitesMenu_titleClosed__3wNbT .SitesMenu_location__DGTgU,
  .SitesMenu_titleWrapper__27-hb.SitesMenu_titleClosed__3wNbT .SitesMenu_icon__ertKO {
    color: #c8ced3; }
  .SitesMenu_titleWrapper__27-hb:hover .SitesMenu_location__DGTgU,
  .SitesMenu_titleWrapper__27-hb:hover .SitesMenu_icon__ertKO {
    color: #fff; }

.SitesMenu_location__DGTgU {
  display: inline-block;
  margin-right: 10px;
  text-transform: uppercase;
  color: #c8ced3;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
  font-size: 14px; }
  @media (min-width: 46.875em) {
    .SitesMenu_location__DGTgU {
      font-size: 16px; } }

.SitesMenu_icon__ertKO {
  color: #c8ced3;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out; }

.SitesMenu_dropdown__1ekMf {
  background-color: #3a4149;
  border: 2px solid #23282c; }

.Sidenav_sidenav__1pX18 {
  grid-area: sidenav;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: calc(100vh - 50px);
  width: 240px;
  position: fixed;
  top: 50px;
  left: -245px;
  padding-top: 40px;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2;
  background-color: #3a4149;
  border-right: 1px solid #23282c;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out; }
  @media (min-width: 46.875em) {
    .Sidenav_sidenav__1pX18 {
      left: 0;
      padding-top: 0; } }

.Sidenav_open__1WdDX {
  left: 0; }

.Sidenav_closed__V8u-i {
  left: -245px; }

.Sidenav_closeIcon__1ckcP {
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px; }
  .Sidenav_closeIcon__1ckcP:hover .Sidenav_icon__bPtAb {
    color: white; }

.Sidenav_show__1d355 {
  display: inline-block; }

.Sidenav_hide__1ikgs {
  display: none; }

.Sidenav_icon__bPtAb {
  float: right;
  color: #c8ced3; }
  .Sidenav_icon__bPtAb:hover {
    color: #fff; }

.Sidenav_list__3eTCK {
  padding: 0;
  margin-top: 85px;
  list-style-type: none; }

.Sidenav_listItem__3qH2R {
  padding: 20px 20px 20px 40px;
  color: #ddd; }
  .Sidenav_listItem__3qH2R:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer; }

.Sidenav_menuIcon__MNB76 {
  margin-right: 10px;
  color: #73818f; }

.Sidenav_version__1Dl2P {
  margin: 15px;
  color: #adadad; }

.Sidenav_versionOutdated__1nHsd {
  color: #f86c6b; }

.Footer_footer__2A9L6 {
  grid-area: footer;
  background-color: #3a4149;
  border-top: 1px solid #23282c;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 20px; }

.Footer_footerCopyright__1iftY {
  display: block;
  color: #fff; }

.Footer_footerSignature__2dU9e {
  display: block;
  color: #fff; }

