@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.gridContainer {
  display: grid;
  grid-template-columns: 1fr; /* Side nav is hidden on mobile */
  grid-template-rows: 1fr 50px;
  grid-template-areas:
    'main'
    'footer';
  width: 100%;
  height: 100vh;
}

.navOpen {
  @include breakpoint($non-mobile) {
    grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
    grid-template-areas:
      'sidenav main'
      'sidenav footer';
  }
}
