.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 40vw;
  color: rgba(34, 31, 31, 0.1);
}
