.toaster {
  position: absolute;
  top: 60px;
  right: 15px;
  z-index: 499;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.25rem;
  color: #e4e7ea;
}
