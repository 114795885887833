@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 240px;
  position: fixed;
  top: 50px;
  left: -245px;
  padding-top: 40px;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2;
  background-color: #3a4149;
  border-right: 1px solid #23282c;
  transition: all 0.6s ease-in-out;

  @include breakpoint($non-mobile) {
    left: 0;
    padding-top: 0;
  }
}

.open {
  left: 0;
}

.closed {
  left: -245px;
}

.closeIcon {
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    .icon {
      color: white;
    }
  }
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.icon {
  float: right;
  color: #c8ced3;

  &:hover {
    color: #fff;
  }
}

.list {
  padding: 0;
  margin-top: 85px;
  list-style-type: none;
}

.listItem {
  padding: 20px 20px 20px 40px;
  color: #ddd;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
}

.menuIcon {
  margin-right: 10px;
  color: #73818f;
}

.version {
  margin: 15px;
  color: #adadad;
}

.versionOutdated {
  color: #f86c6b;
}
