.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #3a4149;
  border: 1px solid #23282c;
  border-radius: 0.25rem;
}
.body {
  width: 100%;
  flex: 1 1 auto;
  padding: 20px 20px 30px;
  color: #e4e7ea;
}
.title {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.date {
  font-size: 14px;
  line-height: 14px;
  color: #73818f;
}
.report {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  .value {
    .number {
      display: block;
      position: relative;
      top: 5px;
      min-width: 65px;
      margin-right: 5px;
      font-size: 32px;
      line-height: 32px;
    }
  }
  .graph {
    flex-grow: 1;
    .units {
      font-size: 13px;
      color: #73818f;
    }
    .bar {
      height: 5px;
      background-color: #515b65;
    }
  }
}

.temp {
  div {
    background-color: #20a8d8;
  }
}
.light {
  div {
    background-color: #4dbd74;
  }
}
.humidity {
  div {
    background-color: #ffc107;
  }
}
