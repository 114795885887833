@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.titleWrapper {
  cursor: pointer;

  &.titleOpen {
    .location,
    .icon {
      color: #fff;
    }
    .icon {
      transform: rotate(45deg);
    }
  }

  &.titleClosed {
    .location,
    .icon {
      color: #c8ced3;
    }
  }
  &:hover {
    .location,
    .icon {
      color: #fff;
    }
  }
}

.user {
  display: none;
  position: relative;
  margin: 0 10px;
  text-transform: uppercase;
  color: #c8ced3;
  transition: all 1s ease-out;

  @include breakpoint($non-mobile) {
    display: inline-block;
  }
}

.icon {
  color: #c8ced3;
  transition: all 1s ease-out;
}

.dropdown {
  background-color: #3a4149;
  border: 2px solid #23282c;
}
