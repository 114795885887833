@import 'breakpoint-sass/stylesheets/_breakpoint.scss';
@import '_breakpoints.scss';

.footer {
  grid-area: footer;
  background-color: #3a4149;
  border-top: 1px solid #23282c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.footerCopyright {
  display: block;
  color: #fff;
}

.footerSignature {
  display: block;
  color: #fff;
}
