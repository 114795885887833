.button {
  position: relative;
  width: 100%;
  padding: 10px;
  border: 1px solid #23282c;
  background-color: #343b41;
  border-radius: 0.25rem;
  color: #e4e7ea;
  text-align: left;
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.open {
  display: block;
  
  svg {
    color: inherit;
  }
}

.closed {
  display: block;
  
  svg {
    color: #fff;
  }
}

.label {
  color: #73818f;
}

.value {
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 15px;

  svg {
    font-size: 22px;
  }
}

.chart {
  margin-top: 15px;
}

.blue {
  color: #20a8d8;
}

.green {
  color: #4dbd74;
}

.yellow {
  color: #ffc107;
}

.red {
  color: #f86c6b;
}
